@import './variables.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Raleway, sans-serif;
  background-color: $background;
  color: $black;
}

h1 {
  line-height: 1em;
  display: block;
  font-size: 3.5em;
  margin: 0 0 0.7em 0;
  font-weight: 700;
}

h2 {
  line-height: 1em;
  display: block;
  font-size: 1.5em;
  margin: 0 0 0.7em 0;
  font-weight: 300;
}

h3 {
  line-height: 1.3em;
  display: block;
  font-size: 1.1em;
  margin: 0 0 0.7em 0;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
strong,
a {
  &.gray {
    color: $gray;
  }
  &.dark-gray {
    color: $dark-gray;
  }
  &.light-gray {
    color: $light-gray;
  }
  &.black {
    color: $black;
  }
  &.blue {
    color: $blue;
  }
  &.white {
    color: $white;
  }
  &.purple {
    color: $purple;
  }
  &.light-purple {
    color: $light-purple;
  }
}

p {
  margin: 0px 0px 25px 0px;
  color: $dark-gray;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  align-self: flex-start;
}

strong {
  font-weight: 700;
}

a {
  color: $dark-gray;
  cursor: pointer;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}


.btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.btn {
  border: none;
  border-radius: 25px 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 9px 15px;
  color: $blue;
  background-color: $white;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, opacity 0.2s;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(63, 125, 196, 0.2);
  }

  &:hover {
    background-color: $dark-blue;
    color: $white;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &.blue {
    background-color: $blue;
    color: $white;

    &:hover {
      background-color: $dark-blue;
    }
  }

  &.clear {
    background-color: transparent;

    &:hover {
      color: $blue;
      opacity: 0.8;
    }
  }
}

.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}
