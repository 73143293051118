$white: #fff;
$black: #242424;
$dark-gray: #666;
$gray: #9e9e9e;
$light-gray: #cacaca;
$overlay-gray: #0000004d;
$background: #f1f1f1;

$dark-blue: #3f7dc4;
$blue: #4a90e2;
$light-blue: #7ab8ff;
$red: #f44336;
$light-purple: #94a1f5;
$purple: #7d8df3;;