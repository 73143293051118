@import './variables.scss';

.input {
	position: relative;
	display: flex;
	  width: 100%;
	  
	.input-container {
		position: relative;
		display: flex;
		width: 100%;
		  
		.input-field {
			height: 55px;
			padding: 10px 95px 10px 10px;
			background-color:	$white;
			border: none;
			border-radius: 2px;
			width: 100%;
			color: $light-gray;
			outline: none;
			z-index: 0;
         	-webkit-tap-highlight-color: transparent;
			line-height: 1;
			font-size: 16px;
			color: $black;

			&::-webkit-input-placeholder {
				color: $light-gray;
				line-height: 1;
				padding-top: 2px;
			}

			&:focus + .input-field-shadow,
            &:hover + .input-field-shadow {
                box-shadow: 0px 2px 10px #d8ddec;
			}
		}

		.input-field-shadow {
			position: absolute;
			display: flex;
			width: 99%;
			height: 95%;
			bottom: 0;
			left: 1%;
			z-index: -10;
			box-shadow: 0px 0px 5px #d8ddec;
			border-radius: 2px;
         	transition: all ease 240ms;
		}
	}
		
	.submit-btn {
		position: absolute;
		right: -10px;
		top: 7.5px;
		transition: all ease 240ms;
		border: none;
		border-radius: 2px;

		background-color:$light-purple;
		color: $white;
		text-transform: uppercase;
		-webkit-appearance: none;
		transition: all ease 240ms;
		cursor: pointer;
		min-width: 95px;
		height: 40px;
		box-shadow: 0px 2px 10px  #d8ddec;

		&:hover {
			background-color: $purple;
		}

		&:focus {
			outline: none;
			box-shadow: 0 0 0 3px rgba(63, 125, 196, 0.2);
			background-color: $purple;
			}
		
			&:active {
			outline: none;
			box-shadow: none;
			}
	}
}
