@import './variables.scss';

.content {
  min-height: calc(100% - 50px);
  width: 100%;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  margin-top: 50px;
  text-align: center;
  
  h1,h2 {
    span {
      display: none;
    }
  }
}


.success-message {
  width: 100%;
}

.emojis {
  font-size: 25px;
}

.success-message {
  padding-top: 15px;
  padding-bottom: 85px;
}
form {
  padding: 5px;
  margin: 0 0 0.7em 0;
  max-width: 523.375px;
  width: 100%;
  min-height: 100px;
  display: grid;
  align-items: center;
  gap: 10px;
}

@media (min-width: 500px) {
  .content {
    
    h2 {
      margin: 0 0 0.1em 0;
    }
    h1 {
      font-size: 3.3em;
      margin: 0 0 0.3em 0;
    }
    h3 {
      margin: 0;
    }
    form {
      margin: 0 0 0.2em 0;
    }
  }
}

@media (min-width: 700px) {
  .content {
    
    h2 {
      span {
        display: inline;
      }
    }

    h2 {
      order: 1;
      font-size: 1.7em;
      margin: 0 0 1.1em 0;
    }
    h1 {
      margin: 0 0 0.5em 0;
      order: 2;
      font-size: 4.5em;
    }
    h3 {
      order: 3;
      margin: 0 0 0.9em 0;
    }
    form {
      order: 4;
    }
  }
}

@media (min-width: 1000px) {
  .content {
    h1 {
      span {
        display: inline;
      }
    }
  }
}