@import './variables.scss';

header {
    background-color: $white;
    position: fixed;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;

    .logo {
        color: $black;
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 0 20px;
    }
}